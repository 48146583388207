import { createGlobalStyle } from 'styled-components'
import * as COLORS from '../../constants/colors'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    min-width: 1366px;
    position: relative;
  }


  body {
    margin: 0;
    font-family: 'Work Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(135deg, rgba(101,169,202,1) 0%, rgba(40,44,73,1) 100%);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  input,
  button {
    box-shadow: none !important;
  }

  input:focus {
    border: 1px solid ${COLORS.PRIMARY}
  }

  .form-control.is-invalid {
    background-image: none;
    padding-right: 1em;
  }

  .form-control.is-valid {
    background-image: none;
    padding-right: 1em;
  }

  .tooltip {
    font-family: 'Work Sans', sans-serif !important;
  }

  .tooltip-inner {
    max-width: 350px;
    width: inherit;
  }

  .icon-12 {
    font-size: 12px !important
  }

  .icon-14 {
    font-size: 14px !important
  }

  .icon-16 {
    font-size: 16px !important
  }

  .icon-18 {
    font-size: 18px !important
  }

  .icon-32 {
    font-size: 32px !important
  }

  .icon-bold {
    font-weight: bold
  }

  .icon-primary {
    color: ${COLORS.PRIMARY}
  }

  .icon-white {
    color: ${COLORS.WHITE}
  }

  .icon-primary-alt {
    color: ${COLORS.PRIMARY_ALT}
  }

  .icon-grey {
    color: ${COLORS.GREY}
  }

  .icon-danger {
    color: red
  }

  .btn-primary {
    background-color: ${COLORS.PRIMARY} !important;
    border-color: ${COLORS.PRIMARY} !important;
  }

  .btn-outline-primary {
    color: ${COLORS.PRIMARY_ALT};
    border-color: ${COLORS.PRIMARY_ALT};
  }

  .btn-outline-primary:hover {
    color: ${COLORS.WHITE};
    border-color: ${COLORS.PRIMARY_ALT};
    background-color: ${COLORS.PRIMARY_ALT};
  }

  .btn-sm {
    padding: 0px 8px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    width: 100%;
  }

  .modal-xl {
    max-width: calc(100% - 64px);
  }

  .cursor-pointer {
    cursor: pointer !important
  }

  .modal-90w {
    width: 90vw;
    max-width: 90vw;
  }

  .form-label {
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  .invalid-feedback {
    display: block;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 500px !important;
  }

  .modal-backdrop {
    z-index: 400 !important
  }

  .modal {
    z-index: 500 !important
  }

  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper, 
  .customDatePickerWidth > div > div.react-datepicker__input-container
  .customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
  }

  #messageEditor .ql-toolbar {
    border-radius: 6px 6px 0 0;
  }

  #messageEditor .ql-container {
    border-radius: 0 0 6px 6px;
  }
`
