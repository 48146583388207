import React, { createContext, useContext, useState } from 'react'
import { Card as BootstrapCard } from 'react-bootstrap'
import { Card, Text } from '../Themed'
import { gql, useQuery } from '@apollo/client'
import styled, { css } from 'styled-components'
import { NEUTRAL_2, PRIMARY_1 } from '../../constants/colors'
import Loader from '../Loader'
import CreateConversation from './CreateConversation'
import { CONVERSATION_FIELDS } from './CreateConversation'
import ConversationFilter from './ConversationFilter'

// const { REACT_APP_HST_COMPANY_ID } = process.env

export const ConversationsContext = createContext({ sites: [], companies: [] })

const PRINCIPLE_QUERY = gql`
  query PRINCIPLE_QUERY(
    $companiesFilter: JSON!
    $sitesFilter: JSON!
    $usersFilter: JSON!
    $landownersFilter: JSON!
  ) {
    companies: findCompaniesAdmin(filter: $companiesFilter) {
      id
      name
    }
    sites: findSitesAdmin(filter: $sitesFilter) {
      id
      name
      landowners {
        all {
          id
          name
          email
        }
        anchor {
          id
          name
          email
        }
      }
    }
    users: findUsersAdmin(filter: $usersFilter) {
      id
      firstName
      lastName
      email
      company {
        id
      }
    }
    landowners: findLandownersAdmin(filter: $landownersFilter) {
      id
      name
      email
    }
  }
`

const FIND_CONVERSATIONS_ADMIN = gql`
  ${CONVERSATION_FIELDS}
  query FIND_CONVERSATIONS_ADMIN($filter: JSON!) {
    conversations: findConversationsAdmin(filter: $filter) {
      ...ConversationFields
    }
  }
`

export const Stack = styled.div`
  display: flex;
  align-self: stretch;
  ${props =>
    props.direction === 'horizontal'
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : css`
          flex-direction: column;
          flex: 1 1 auto;
        `}
  row-gap: ${({ gap }) => (gap || 0) * 0.25}rem !important;
  column-gap: ${({ gap }) => (gap || 0) * 0.25}rem !important;
`

function Threads({ filter }) {
  const { sites, companies } = useContext(ConversationsContext)

  const { loading, error, data } = useQuery(FIND_CONVERSATIONS_ADMIN, {
    variables: { filter },
  })

  if (loading) return <p>loading...</p>
  if (error) return <p>error...</p>

  const { conversations } = data

  if (!conversations.length)
    return (
      <Stack className="align-items-center justify-content-center h-100">
        <Text size={12} lines={Infinity} className="text-center">
          Uh-oh! It looks like our search party came back empty-handed. Maybe
          try a different path, or
          <br /> check if the search goblins ate your keywords!
        </Text>
      </Stack>
    )

  const populatedConversations = conversations.map(conversation => {
    const company = companies.find(company =>
      Object.is(company.id, conversation.company)
    )
    const site = sites.find(site => Object.is(site.id, conversation.site))

    return { ...conversation, company, site }
  })

  return (
    <BootstrapCard className="p-2" style={{ background: NEUTRAL_2 }}>
      {populatedConversations.map(conversation => (
        <Stack
          direction="horizontal"
          className="justify-content-between border-bottom py-1 cursor-pointer"
        >
          <Text size={14}>
            {conversation.company.name} | {conversation.site.name}
          </Text>
          <Text size={14} weight="bold">
            {conversation.subject}
          </Text>
          <Text size={14}>
            {new Date(conversation.updatedAt).toLocaleString('en-US', {
              timeStyle: 'short',
              dateStyle: 'short',
            })}
          </Text>
        </Stack>
      ))}
    </BootstrapCard>
  )
}

function SiteConversations() {
  const [filter, setFilter] = useState({
    sites: [],
    companies: [],
    sort: { updatedAt: -1 },
  })

  const handleFilterChange = data => setFilter({ ...filter, ...data })

  return (
    <React.Fragment>
      <ConversationFilter onChange={handleFilterChange} />
      <Threads filter={filter} />
    </React.Fragment>
  )
}

function Conversations() {
  const { loading, error, data } = useQuery(PRINCIPLE_QUERY, {
    variables: {
      companiesFilter: {},
      sitesFilter: { isPublished: true },
      usersFilter: {},
      landownersFilter: {},
    },
  })

  if (loading) return <Loader />
  if (error) return <p>error...</p>

  const { sites, companies, users, landowners } = data

  return (
    <Card padding={16}>
      <ConversationsContext.Provider
        value={{ sites, companies, users, landowners }}
      >
        <Stack direction="horizontal" className="justify-content-between">
          <Text size={24} weight="bold" color={PRIMARY_1}>
            Conversations
          </Text>
          <CreateConversation />
        </Stack>
        <hr className="mt-1 mb-2" />
        <SiteConversations />
      </ConversationsContext.Provider>
    </Card>
  )
}

export default Conversations
